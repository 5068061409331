import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Switch, Button, Typography, Row, Col, message, Divider, Upload } from 'antd';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { PumpFormValues, PumpPart, PumpRecord, Vendor } from '../utils/types'; // Adjust the path as necessary
import { useAuth } from '../auth/authContext'; // Import useAuth hook

const { Option } = Select;
const { Title } = Typography;

const EditPumpPage: React.FC = () => {
  const navigate = useNavigate();
  const { pumpId } = useParams<{ pumpId: string }>();
  const [form] = Form.useForm();
  const { token } = useAuth(); // Get token from AuthContext
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<PumpFormValues | null>(null);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [partTypes, setPartTypes] = useState<{ id: number, name: string }[]>([]);
  const [fileList, setFileList] = useState<any[]>([]); // State to manage uploaded files

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/vendors`);
        setVendors(response.data);
      } catch (error) {
        message.error('Failed to fetch vendors');
        console.error('Error fetching vendors:', error);
      }
    };

    const fetchPartTypes = async () => {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/part-types`);
        setPartTypes(response.data);
      } catch (error) {
        message.error('Failed to fetch part types');
        console.error('Error fetching part types:', error);
      }
    };

    fetchVendors();
    fetchPartTypes();
  }, [token]);

  useEffect(() => {
    const fetchPumpDetails = async () => {
      setLoading(true);
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.get<PumpRecord>(`${process.env.REACT_APP_API_URL}/pumps/${pumpId}`);
        const pumpData = response.data;

        const initialValues: PumpFormValues = {
          name: pumpData.name,
          application_name: pumpData.application_name,
          vendor_id: pumpData.vendor.id,
          plant_id: pumpData.plant_id,
          vsd_controlled: pumpData.parts.some((part: PumpPart) => part.part_type_id === 5 && part.part_value === 'Yes'),
          motor_size: getPartValue(pumpData.parts, 1),
          pully_for_motor: getPartValue(pumpData.parts, 2),
          pully_for_pump: getPartValue(pumpData.parts, 3),
          pump_speed: getPartValue(pumpData.parts, 4),
          sealing: getPartValue(pumpData.parts, 6),
          bearing_size: getPartValue(pumpData.parts, 7),
          pump_size: getPartValue(pumpData.parts, 8),
          pipe_size: getPartValue(pumpData.parts, 17),
          pipe_length: getPartValue(pumpData.parts, 18),
          static_discharge_head: getPartValue(pumpData.parts, 19),
          required_flow_rate: getPartValue(pumpData.parts, 20),
          pump_serial_number: getPartValue(pumpData.parts, 9),
          impeller: getPartValue(pumpData.parts, 11),
          liners: getPartValue(pumpData.parts, 12),
          volute: getPartValue(pumpData.parts, 13),
          throat_bush: getPartValue(pumpData.parts, 14),
          packing: getPartValue(pumpData.parts, 15),
          shaft_sleeve: getPartValue(pumpData.parts, 16),
          file: pumpData.file_path
        };
        setInitialValues(initialValues);
      } catch (error) {
        console.error('Failed to fetch pump details:', error);
        message.error('Failed to fetch pump details');
      } finally {
        setLoading(false);
      }
    };

    fetchPumpDetails();
  }, [pumpId, token]);

  const getPartValue = (parts: PumpPart[], partTypeId: number) => {
    const part = parts.find((part) => part.part_type_id === partTypeId);
    return part ? part.part_value : '';
  };

  const handleCancel = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleFileChange = ({ fileList }: any) => setFileList(fileList);

  const handleEdit = async (values: PumpFormValues) => {
    const parts = [
      { part_type_name: 'Motor Size', part_value: values.motor_size },
      { part_type_name: 'Pully for motor', part_value: values.pully_for_motor },
      { part_type_name: 'Pully for pump', part_value: values.pully_for_pump },
      { part_type_name: 'Pump speed', part_value: values.pump_speed },
      { part_type_name: 'VSD controlled', part_value: values.vsd_controlled ? 'Yes' : 'No' },
      { part_type_name: 'Sealing', part_value: values.sealing },
      { part_type_name: 'Bearing Size', part_value: values.bearing_size },
      { part_type_name: 'Pump Size', part_value: values.pump_size },
      { part_type_name: 'Pipe Size', part_value: values.pipe_size },
      { part_type_name: 'Pipe Length', part_value: values.pipe_length },
      { part_type_name: 'Static Discharge Head', part_value: values.static_discharge_head },
      { part_type_name: 'Required Flow Rate', part_value: values.required_flow_rate },
      { part_type_name: 'Pump Serial number', part_value: values.pump_serial_number },
      { part_type_name: 'Impeller', part_value: values.impeller },
      { part_type_name: 'Liners', part_value: values.liners },
      { part_type_name: 'Volute', part_value: values.volute },
      { part_type_name: 'Throat bush', part_value: values.throat_bush },
      { part_type_name: 'Packing', part_value: values.packing },
      { part_type_name: 'Shaft sleeve', part_value: values.shaft_sleeve },
    ];

    const plant_id = localStorage.getItem('plant_id');
    if (!plant_id) {
      throw new Error("Plant ID is missing.");
    }
    values.plant_id = parseInt(plant_id);

    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('application_name', values.application_name);
    formData.append('vendor_id', values.vendor_id.toString());
    formData.append('plant_id', values.plant_id.toString());
    // Adding the _method field to simulate a PUT request

    parts.forEach((part, index) => {
      formData.append(`parts[${index}][part_type_name]`, part.part_type_name);
      formData.append(`parts[${index}][part_value]`, part.part_value);
    });

    if (fileList.length > 0) {
      const file = fileList[0].originFileObj || fileList[0];
      formData.append('file', file);
    }

    try {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      await axios.post(`${process.env.REACT_APP_API_URL}/pumps/${pumpId}/update`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Pump updated successfully');
      // form.resetFields(); // Clear the form
      setFileList([]); // Clear the file list
      navigate(-1); // Navigate back to the previous page
    } catch (error) {
      console.error('Failed to update pump:', error);
      message.error('Failed to update pump');
    }
  };

  // Calculate pump speed whenever "Pully for Motor" or "Pully for Pump" changes
  const onValuesChange = (_: any, allValues: PumpFormValues) => {
    const motorPulley = allValues.pully_for_motor;
    const pumpPulley = allValues.pully_for_pump;
    
    if (motorPulley && pumpPulley) {
      let pumpSpeed = (parseFloat(motorPulley) / parseFloat(pumpPulley)) * 1460;
  
      // Check for infinity or NaN and set to 0
      if (!isFinite(pumpSpeed)) {
        pumpSpeed = 0;
      }
  
      console.log(pumpSpeed, 'pumpSpeed');
      form.setFieldsValue({ pump_speed: pumpSpeed.toFixed(2) });
    }
  };

  if (loading || !initialValues) {
    return <div>Loading...</div>;
  }

  return (
    <div style={styles.container}>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={3} style={{ color: '#21456A', fontWeight: 'bold', marginTop: '4px' }}>Edit {initialValues.application_name}</Title>
        </Col>
        <Col>
          <Button shape="circle" icon={<CloseOutlined />} style={{ border: 'none', color: '#21456A', boxShadow: 'none' }} onClick={handleCancel} />
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleEdit}
        style={styles.form}
        scrollToFirstError
        onValuesChange={onValuesChange}
      >
        <Divider>Application Data</Divider>
        <Form.Item label="Application Name" name="application_name" rules={[{ required: true, message: 'Please enter application name' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Pump Name" name="name" rules={[{ required: true, message: 'Please enter pump name' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Vendor" name="vendor_id" rules={[{ required: true, message: 'Please select vendor' }]}>
          <Select>
            {vendors.map(vendor => (
              <Option key={vendor.id} value={vendor.id}>
                                {vendor.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="VSD Controlled" name="vsd_controlled" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Motor Size" name="motor_size" rules={[{ required: true, message: 'Please enter motor size' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Pully for Motor" name="pully_for_motor" rules={[{ required: true, message: 'Please enter pully for motor' }]}>
          <Input type='number' />
        </Form.Item>
        <Form.Item label="Pully for Pump" name="pully_for_pump" rules={[{ required: true, message: 'Please enter pully for pump' }]}>
          <Input type='number'/>
        </Form.Item>
        <Form.Item label="Pump Speed" name="pump_speed" rules={[{ required: true, message: 'Please enter pump speed' }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item label="Sealing" name="sealing" rules={[{ required: true, message: 'Please enter sealing' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Bearing Size" name="bearing_size" rules={[{ required: true, message: 'Please enter bearing size' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Pump Size" name="pump_size" rules={[{ required: true, message: 'Please enter pump size' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Pipe Size" name="pipe_size" rules={[{ required: true, message: 'Please enter pipe size' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Pipe Length" name="pipe_length" rules={[{ required: true, message: 'Please enter pipe length' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Static Discharge Head" name="static_discharge_head" rules={[{ required: true, message: 'Please enter static discharge head' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Required Flow Rate" name="required_flow_rate" rules={[{ required: true, message: 'Please enter required flow rate' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Pump Serial Number" name="pump_serial_number" rules={[{ required: true, message: 'Please enter pump serial number' }]}>
          <Input />
        </Form.Item>

        {/* File Upload */}
        <Form.Item label="Upload New Pump File" name="file">
          <Upload
            beforeUpload={() => false} // Prevent auto upload, we'll handle it manually
            fileList={fileList}
            onChange={handleFileChange}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>

        <Divider>Wearable Data</Divider>

        <Form.Item label="Impeller" name="impeller" rules={[{ required: true, message: 'Please select impeller' }]}>
          <Select>
            <Option value="metal">Metal Impeller</Option>
            <Option value="polyurethane">Polyurethane Impeller</Option>
            <Option value="rubber">Rubber Impeller</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Liners" name="liners" rules={[{ required: true, message: 'Please select liners' }]}>
          <Select>
            <Option value="metal">Metal Liners</Option>
            <Option value="polyurethane">Polyurethane Liners</Option>
            <Option value="rubber">Rubber Liners</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Volute" name="volute" rules={[{ required: true, message: 'Please select volute' }]}>
          <Select>
            <Option value="metal">Metal Volute</Option>
            <Option value="polyurethane">Polyurethane Volute</Option>
            <Option value="rubber">Rubber Volute</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Throat Bush" name="throat_bush" rules={[{ required: true, message: 'Please select throat bush' }]}>
          <Select>
            <Option value="metal">Metal Throat Bush</Option>
            <Option value="polyurethane">Polyurethane Throat Bush</Option>
            <Option value="rubber">Rubber Throat Bush</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Packing" name="packing" rules={[{ required: true, message: 'Please enter packing' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Shaft Sleeve" name="shaft_sleeve" rules={[{ required: true, message: 'Please select shaft sleeve' }]}>
          <Select>
            <Option value="stainless">Stainless Shaft Sleeve</Option>
            <Option value="tungsten">Tungsten Shaft Sleeve</Option>
            <Option value="chrome">Chrome Shaft Sleeve</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Button key="back" type="link" onClick={handleCancel} style={{ borderColor: '#2196F3', marginRight: '12px' }} size='large' block>
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()} style={{ background: '#21456A' }} size='large' block>
                Update
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

const styles = {
  container: {
    padding: '16px',
  },
  form: {
    marginTop: '16px',
  },
};

export default EditPumpPage;

