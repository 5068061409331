import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Card, Button, message } from 'antd';
import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { PumpRecord, PumpPart } from '../utils/types'; // Ensure the correct path to your types
import { useAuth } from '../auth/authContext'; // Import useAuth hook

const { Title, Text } = Typography;

const PumpTrackingDetails: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const { pumpId } = useParams<{ pumpId: string }>();
  const [pumpDetails, setPumpDetails] = useState<PumpRecord | null>(null);
  const [pumpSize, setPumpSize] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(token){
      fetchPumpDetails();
    }
  }, [token]);

  const fetchPumpDetails = async () => {
    try {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/pumps/${pumpId}`);
      console.log(response.data);
      setPumpDetails(response.data);
      setPumpSize(response.data.parts.filter((component: PumpPart) => component.part_type_id === 8)[0].part_value)
    } catch (error) {
      message.error('Failed to fetch pump details');
      console.error('Failed to fetch pump details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleEditPart = (component: PumpPart) => () => {
    navigate(`/trackingUpdate/${component.id}`); // Navigate to the edit page
  };

  const formatDate = (date: Date) => {
    return moment(date).format('MMMM Do YYYY');
  };

  const formatDaysFromNow = (date: any) => {
    const now = moment();
    const end = moment(date);
    const duration = moment.duration(end.diff(now));
    const days = duration.asDays();
    return `${Math.round(days)} days`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!pumpDetails) {
    return <div>No pump details found.</div>;
  }

  return (
    <div style={styles.container}>
      <Title level={2} style={{ color: '#21456A', marginTop: '0px' }}>Pump Tracking Record</Title>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={3} style={{ color: '#21456A', fontWeight: 'bold', marginTop: '4px' }}>{pumpDetails.name}</Title>
        </Col>
        <Col>
          <Button shape="circle" icon={<CloseOutlined />} style={{ border: 'none', color: '#21456A', boxShadow: 'none' }} onClick={handleBackClick} />
        </Col>
      </Row>
      <Text type="secondary" style={{ color: '#21456A', fontWeight: 'bold' }}>Pump Size: {pumpSize}</Text>
      <br/>
      <Text type="secondary" style={styles.componentText}>Contact person</Text>
      <div style={styles.contactDetails}>
        <Text strong style={{ color: '#21456A', fontWeight: 'bold' }}>{pumpDetails.vendor.name}</Text> - <Text style={styles.componentText}>{pumpDetails.vendor.company}</Text>
      </div>
      <Text style={{ color: '#21456A', fontWeight: 'bold' }}>{pumpDetails.vendor.contact_number}</Text>
      {pumpDetails.parts
        .filter((component: PumpPart) => component.part_type_id >= 10 && component.part_type_id <= 16)
        .map((component, index) => (
          <Card key={index} hoverable style={styles.card} onClick={handleEditPart(component)}>
            <Title level={4} style={{ ...styles.componentTitle, fontWeight: 'bold' }}>{component.part_type.name}</Title>
            <Row align="middle" justify="space-between" style={{ position: 'relative' }}>
              <Col span={10}>
                <Text style={styles.componentText}>Installed:</Text>
                <br />
                <Text style={styles.componentText}>
                  <strong style={{ fontWeight: 'bold' }}>
                    {formatDate(component.latest_tracking.installation_date)}
                  </strong>
                </Text>
              </Col>
              <Col span={14}>
                <Text style={styles.componentText}>Replacement due:</Text>
                <br />
                <Text style={styles.componentText}>
                  <strong style={{ fontWeight: 'bold' }}>
                    {component.part_tracking_report
                      ? formatDaysFromNow(
                          moment(component.latest_tracking.installation_date)
                            .add(component.part_tracking_report ? component.part_tracking_report.average_days_to_replace : 0, 'days')
                        )
                      : 'N/A'}
                  </strong>
                </Text>
              </Col>
            </Row>
            <Button shape="circle" size='large' icon={<ArrowRightOutlined />} style={styles.button}/>
          </Card>
        ))}
    </div>
  );
};

const styles = {
  container: {
    padding: '16px',
  },
  contactDetails: {
    marginBottom: '8px',
  },
  card: {
    marginTop: '16px',
    borderRadius: '8px',
    position: 'relative' as 'relative', // Ensure position relative is set
  },
  componentTitle: {
    color: '#21456A',
    marginTop: '0px',
  },
  componentText: {
    color: '#667085'
  },
  button: {
    borderColor: '#2196F3',
    color: '#21456A',
    position: 'absolute' as 'absolute',
    right: 12,
    top: '50%',
    transform: 'translateY(-50%)', // Center the button vertically
  }
};

export default PumpTrackingDetails;
