import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Descriptions, Divider, message, Row, Col, Typography, Button } from 'antd';
import axios from 'axios';
import { PumpRecord, PumpPart } from '../utils/types';

const { Title } = Typography;

const PumpQRCodeDetails: React.FC = () => {
  const { unique_id, pump_id } = useParams<{ unique_id: string; pump_id: string }>();
  const [pump, setPump] = useState<PumpRecord | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // useHistory hook for navigation

  useEffect(() => {
    const fetchPumpData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/pumpsQRCode/${pump_id}`);
        setPump(response.data);
      } catch (error) {
        console.error('Failed to fetch pump data:', error);
        message.error('Failed to fetch pump data');
      } finally {
        setLoading(false);
      }
    };

    fetchPumpData();
  }, [pump_id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!pump) {
    return <div>Pump not found</div>;
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div style={styles.container}>
      <Row justify="center" align="middle">
        <Col>
          <Title level={3} style={styles.title}>View - {pump.name}</Title>
        </Col>
      </Row>
      <Row justify="center" style={{ marginBottom: '20px' }}>
        <Button onClick={handleBack} type="primary">
          Back to Home
        </Button>
      </Row>
      <Descriptions bordered title="Pump Details" layout="vertical" style={styles.descriptions}>
        <Descriptions.Item label="Pump Name">{pump.name}</Descriptions.Item>
        <Descriptions.Item label="Application Name">{pump.application_name}</Descriptions.Item>
        {pump.parts
          .filter((part: PumpPart) => part.part_type_id >= 1 && part.part_type_id <= 9) // Filter parts with part_type_id between 1 and 9
          .map((part: PumpPart, index: number) => (
            <Descriptions.Item key={index} label={part.part_type.name} style={styles.descriptionItem}>
              {part.part_value}
            </Descriptions.Item>
          ))}
          {/* Filter parts with part_type_id between 11 and 16 and display the old image */}
          {pump.parts
            .filter((part: PumpPart) => part.part_type_id >= 11 && part.part_type_id <= 16)
            .map((part: PumpPart, index: number) => (
              <Descriptions.Item key={`part-image-${index}`} label={part.part_type.name} style={styles.descriptionItem}>
                {part.latest_tracking.old_part_image ? (
                  <img
                    src={`https://pumppromonitorb.co.za/${part.latest_tracking.old_part_image}`}
                    alt={part.part_type.name}
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                ) : (
                  'No image available'
                )}
              </Descriptions.Item>
            ))}
      </Descriptions>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    textAlign: 'center' as 'center',
  },
  title: {
    color: '#21456A',
    fontWeight: 'bold' as 'bold',
    marginTop: '4px',
  },
  descriptions: {
    marginTop: '20px',
    width: '100%',
    display: 'inline-block',
    textAlign: 'left' as 'left', // Ensure the description text is left-aligned
  },
  descriptionItem: {
    textAlign: 'left' as 'left', // Left align each item
  },
};

export default PumpQRCodeDetails;
